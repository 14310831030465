import Link from "next/link";

export default function FooterCom() {
  return (
    <div>
     
      <div className="footer-container">
        <div className={"foot_div"}>
          {/* <span className="text">
            © Powered by<Link href={"https://fabboxstudios.com/"} prefetch={false}>
            <a target={"_blank"} className="text"> Fabbox Studios</a>
          </Link>
          </span> */}
           <span className="text">
            © Powered by Fabbox Studios
          </span>
        </div >
        <div className={"foot_div"}>
          <Link href={"/our-partners"} prefetch={false}>
            <a className="footer-text text">Our Partners</a>
          </Link >
          <Link href={"/free-games-for-your-site"} prefetch={false}>
            <a className="footer-text text">Free Games</a>
          </Link>
          <Link href={"/contact-us"} prefetch={false}>
            <a className="footer-text text">Contact us</a>
          </Link>
          <Link href={"/privacy-policy"} prefetch={false}>
            <a className="footer-text text">Privacy Policy</a>
          </Link>
          {/* <Link href={"/blog"} prefetch={false}>
            <a className="footer-text text">Blog</a>
          </Link> */}
        </div>
      </div>
    </div>
  );
}
